<template>
	<a-row class="login_registration">
		<a-col :span="24" class="logo_box">
			<img src="../assets/logo_1.png" alt="logo" class="logo cursor-pointer" @click="logoTap" />
			<div class="text">餐饮门店包间预订管理平台</div>
		</a-col>
		<a-col :span="24" class="wrap">
			<a-row type="flex" justify="center">
				<a-col :xs="0" :sm="0" :md="0" :lg="0" :xl="12" class="box_model">
					<div class="slogan_tit_1">上万家餐厅都在用</div>
					<div class="slogan_tit_2">简单好用 客户信赖之选</div>
					<div class="company_logo_box">
						<img src="../assets/company_logo.png" alt="company_logo" class="company_logo" />
					</div>
				</a-col>
				<a-col :sm="24" :md="24" :lg="24" :xl="12" class="box_model">
					<!-- 注册或登录 -->
					<slot></slot>
				</a-col>
			</a-row>
		</a-col>
		<a-col :span="24" class="bottom_box">
			<div class="text_1">—— 订个包间 - 餐饮门店包间预订管理平台 ——</div>
			<div class="text_2">
				@2020-2025 - 订个包间 -
				<a href="https://beian.miit.gov.cn/">津ICP备2021004920号-12</a>
			</div>
		</a-col>
	</a-row>
</template>
<script>
export default {
	props: {
		pageType: {
			type: String,
			default() {
				return ''
			}
		}
	},
	methods: {
		logoTap() {
			window.open('https://www.dinggebaojian.com')
		}
	}
}
</script>
<style lang="less" scoped>
.login_registration {
	width: 100%;
	height: 100%;
	padding-top: 60px;
	box-sizing: border-box;
	position: relative;
	.logo_box {
		width: 100%;
		.logo {
			width: 182px;
			height: auto;
			margin: 0 auto;
			display: block;
		}
		.text {
			width: 100%;
			font-weight: 500;
			font-size: 24px;
			color: #666666;
			text-align: center;
			margin-top: 19px;
		}
	}
	.wrap {
		width: 100%;
		margin-top: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		.box_model {
			width: 520px;
			height: 548px;
			background: #ffffff;
			box-shadow: 0 2px 10px 1px #ebebeb;
			border-radius: 2px;
			margin: 0 10px 8px;
			&:first-child {
				padding-top: 45px;
				box-sizing: border-box;
			}
			&:last-child {
				padding: 45px 35px 0;
				box-sizing: border-box;
			}
			.slogan_tit_1 {
				font-weight: 500;
				font-size: 30px;
				color: #333333;
				text-align: center;
			}
			.slogan_tit_2 {
				font-weight: 500;
				font-size: 24px;
				color: #333333;
				text-align: center;
				margin-top: 42px;
			}
			.slogan_tit_3 {
				font-weight: 500;
				font-size: 20px;
				color: #e43937;
				margin-left: 36px;
				flex-shrink: 0;
				white-space: nowrap;
			}
			.company_logo_box {
				width: 100%;
				margin-top: 71px;
				.company_logo {
					margin: 0 auto;
					width: 454px;
					height: 210px;
					display: block;
				}
			}
		}
	}
	.bottom_box {
		width: 100%;
		margin-top: 50px;
		.text_1 {
			font-weight: 400;
			font-size: 14px;
			color: #666666;
			text-align: center;
		}
		.text_2 {
			font-weight: 400;
			font-size: 12px;
			color: #999999;
			text-align: center;
			margin-top: 10px;
		}
	}
}
.flex {
	width: 100%;
	display: flex;
	align-items: center;
}
</style>
